import React from "react"
import starIcon from "../../assets/images/star-icon.png"
import client1 from "../../assets/images/testimonials/client1.jpg"
import client2 from "../../assets/images/testimonials/client2.jpg"
import client3 from "../../assets/images/testimonials/client3.jpg"

const TestimonialsStyleOne = () => {
  return (
    <div className="testimonials-area pt-100 pb-70 bg-f1f8fb">
      <div className="container">
        <div className="section-title">
          <span className="sub-title">
            <img src={starIcon} alt="about" />
            Testimonials
          </span>
          <h2>What Our Clients are Saying?</h2>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna.
          </p>
        </div>

        <div className="row">
          <div className="col-lg-6 col-md-6">
            <div className="single-testimonials-item">
              <p>
                Went above and beyond when most would give up he saw it through.
                Excellent work 100% recommend
              </p>
              <div className="client-info">
                <div className="d-flex justify-content-center align-items-center">
                  <img src={client1} alt="about" />
                  <div className="title">
                    <h3>Stephen Robinson</h3>
                    <span>Samurai computing</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-6 col-md-6">
            <div className="single-testimonials-item">
              <p>
                Fareed did a great job on our CSS - he was able to edit rules to
                get exactly the look we needed without causing any disruption to
                our html and code. Highly recommended - a real CSS pro.
              </p>
              <div className="client-info">
                <div className="d-flex justify-content-center align-items-center">
                  <img src={client2} alt="about" />
                  <div className="title">
                    <h3>Free Trade</h3>
                    <span>member.cash</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-6 col-md-6">
            <div className="single-testimonials-item">
              <p>
                This developer is by far the best react person I have found on
                here - A real find. I would recommend 110% for anyone. He went
                above and beyond any other I have found on here. working through
                the night, early morning and I cant thank him enough. His React
                / twilio skills are second to none on here. people say they can
                do it but this person is 100% able to.
              </p>
              <div className="client-info">
                <div className="d-flex justify-content-center align-items-center">
                  <img src={client3} alt="about" />
                  <div className="title">
                    <h3>Stephen Robinson</h3>
                    <span>Samurai computing</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-6 col-md-6">
            <div className="single-testimonials-item">
              <p>
              What a great programmer - He did such great work not only in UI but also twilio video streaming. Would 100% recommend and 100% use again. Thanks for all your hard work and patience
              </p>
              <div className="client-info">
                <div className="d-flex justify-content-center align-items-center">
                  <img src={client1} alt="about" />
                  <div className="title">
                  <h3>Stephen Robinson</h3>
                    <span>Samurai computing</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TestimonialsStyleOne
